import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import SimpleInputField from '../SimpleTextField/SimpleTextField';
import * as EmailValidator from 'email-validator';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 12,
  },
  paper: {
    padding: 12,
  },
  control: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    margin: 12,
  },
  button: {
    margin: theme.spacing.unit,
    fontSize: '24px',
  },
  talentCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    minWidth: '200px',
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
});

const validateEmailString = (email, allowEmpty = false) => {
  if (allowEmpty) {
    return !email || EmailValidator.validate(email);
  }
  return !EmailValidator.validate(email);
};

const PendingAuthorizedUser = ({
  pending_authorized_user,
  authorizeAction,
  authorizeTalentAction,
  action,
  deleteAction,
  updateAction,
  userClick,
  classes,
  intl,
}) => {
  return (
    <TableRow>
      <TableCell>
        <SimpleInputField
          id="name"
          required
          label={intl.formatMessage({ id: 'admin_inventory_user.full_name' })}
          data={pending_authorized_user}
          action={action}
          required
        />
      </TableCell>
      <TableCell>
        <SimpleInputField
          id="email"
          validator={validateEmailString(pending_authorized_user.email)}
          required
          type="email"
          label={intl.formatMessage({ id: 'shared.email' })}
          data={pending_authorized_user}
          action={action}
          helperText={
            !validateEmailString(pending_authorized_user.email)
              ? ''
              : intl.formatMessage({ id: 'admin_inventory_user.enter_valid_email' })
          }
          required
        />
      </TableCell>
      <TableCell>
        <div className={classes.talentCell}>
          <WorkIcon color={pending_authorized_user.user?.talent_acquisition_user ? "primary" : "disabled"} />
          {pending_authorized_user.user?.talent_acquisition_user && (
            <span style={{ color: '#1976d2', fontWeight: 500 }}>
              External Talent
            </span>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.actionCell}>
          <Tooltip title="View User">
            <IconButton
              color="primary"
              className={classes.button}
              onClick={() => userClick(pending_authorized_user)}
            >
              <PersonIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Authorize">
            <IconButton
              color="primary"
              className={classes.button}
              onClick={() => authorizeAction(pending_authorized_user)}
            >
              {pending_authorized_user.user?.talent_acquisition_user ? (
                <Icon>assignment_turned_in</Icon>
              ) : (
                <Icon>done</Icon>
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton 
              className={classes.button}
              onClick={() => deleteAction(pending_authorized_user)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
};

const PendingAuthorizedUserWithStyles = withStyles(styles)(PendingAuthorizedUser);
export default injectIntl(PendingAuthorizedUserWithStyles);