import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import InventoryUserDrawer from '../InventoryUserDrawer/InventoryUserDrawer';
import SaveIcon from '@material-ui/icons/Save';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';
import PendingAuthorizedUser from '../PendingAuthorizedUser/PendingAuthorizedUser';
import { filter, mapKeys, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { da } from 'date-fns/locale';

const UserQuery = gql`
  query UserQuery($email: String) {
    getUserByEmail(email: $email) {
      id
      user_name
      birth_date
      annual_revenue
      assets_under_management
      avg_client_age
      bio
      city
      county
      show_city
      number_of_clients
      profile_picture
      province
      recurring_revenue
      created_at
      headline
      my_rating
      nick_name
      obfuscated
      is_pure_buyer
      is_admin
      designations
      tag_list
      transition_goals
      in_auction
      years_practicing
      number_of_employees
      percent_revenue_recurring
      percent_non_recurring_revenue
      percent_fee_based
      percent_commission
      fee_based_revenue
      trailer_revenue
      direct_revenue
      transactional_revenue
      mix_insurance
      mix_mutual_funds
      mix_annuities
      mix_other
      note_count
      product_mix {
        life_ifp
        home_ifp
        auto_ifp
        commercial_ifp
        health_ifp
        group_ifp
        mutual_funds_aum
        securities_aum
        annuities_aua
        segregated_funds_aua
        total_assets
        mcpi_ifp
        private_products_ifp
        crop_hail_ifp
        crop_hail_with_wind_ifp
        farm_insurance_ifp
        price_products_ifp
        replant_supplement_ifp
        gic_fixed_income
        insurance
        equity
      }
    }
  }
`;

const styles = theme => ({
  root       : {
    flexGrow : 1,
    fontSize : '18px',
  },
  paper      : {
    padding : 12,
  },
  control    : {
    padding : theme.spacing(2),
  },
  title      : {
    fontSize : 24,
  },
  saveButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 130,
  },
});

class PendingAuthorizedUsers extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      order       : 'asc',
      orderBy     : 'name',
      selected    : [],
      page        : 0,
      rowsPerPage : 10,
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  modifiedList = pending_authorized_users => filter(pending_authorized_users.data, a => a.modified);

  updateModifiedEmails = (pending_authorized_users, putPendingAuthorizedUser) => {
    this.modifiedList(pending_authorized_users).map(a => putPendingAuthorizedUser(a));
  };

  userClick = async (pendingUser, client) => {
    const { updateSelectedInventoryUser } = this.props;
    try {
      const result = await client.query({
        query: UserQuery,
        variables: { email: pendingUser.email },
        fetchPolicy: 'network-only',
      });  
      const userData = get(result, 'data.getUserByEmail');
   
      updateSelectedInventoryUser({
        user: {
          id: 'X',
          name: pendingUser.name,
          birth_date: userData?.birth_date,
          has_user: true,
          profile_picture: userData?.profile_picture,
          bio: userData?.bio,
          direct_revenue: userData?.direct_revenue,
          recurring_revenue: userData?.recurring_revenue,
          trailer_revenue: userData?.trailer_revenue,
          fee_based_revenue: userData?.fee_based_revenue,
          transactional_revenue: userData?.transactional_revenue,
          total_assets: userData?.assets_under_management,
          user: {
            ...userData,
            email: pendingUser.email,
            talent_acquisition_user: userData?.talent_acquisition_user || false,
          },
        },
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  printError = errors => <h2>{errors.request.responseText}</h2>;

  render () {
    const {
      pending_authorized_users,
      company,
      classes,
      updatePendingAuthorizedUser,
      addPendingAuthorizedUser,
      authorizePendingAuthorizedUser,
      deletePendingUnauthorizedUser,
      deletePendingAuthorizedUser,
      putPendingAuthorizedUser,
      updateSelectedInventoryUser,
      inventory_users,
    } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    return (
      <ApolloConsumer>
        {client => (
          <section>
            <InventoryUserDrawer
              inventoryUser={get(inventory_users, 'inventoryUser.user')}
              inventory_users={[]}
              updateAction={updateSelectedInventoryUser}
              updateInventoryUser={() => {}}
              showBench={false}
              showEditButton={false}
              loadBenchUsers={() => {}}
              inviteButton={() => ''}
              country={'us'}
              openSnack={() => {
                this.handleSnackOpen('User Updated');
              }}
              agentWordForCompany={'agent'}
              currentUserIsAdmin={true}
              refetch={() => ''}
              showActivities={false}
              showFeatures={false}
              showRelated={false}
              onlyUsers={true}
            />
            <Button
              variant="fab"
              color="primary"
              aria-label="add"
              className={classes.saveButton}
              disabled={this.modifiedList(pending_authorized_users).length < 1}
              onClick={() => this.updateModifiedEmails(pending_authorized_users, putPendingAuthorizedUser)}>
              <SaveIcon />
            </Button>
            <Paper className={classes.paper}>
              <Grid container className={classes.root}>
                <Grid container className={classes.demo}>
                  <Table className={classes.root}>
                  {(pending_authorized_users.data || [])
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(a => (
                      <PendingAuthorizedUser
                        key={a.id}
                        pending_authorized_user={a}
                        action={updatePendingAuthorizedUser}
                        authorizeAction={authorizePendingAuthorizedUser}
                        deleteAction={deletePendingUnauthorizedUser}
                        userClick={pendingUser => {
                          this.userClick(pendingUser, client);
                        }}
                      />
                    ))}
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={(pending_authorized_users.data || {}).length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                  {pending_authorized_users.error && (
                    <FormHelperText error>{this.printError(pending_authorized_users.error)}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </section>
        )}
      </ApolloConsumer>
    );
  }
}

export default withStyles(styles)(PendingAuthorizedUsers);
