import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Paper, 
  TextField, 
  Button, 
  Typography,
  Box,
  InputAdornment,
} from '@material-ui/core';
import { gql, useMutation, ApolloProvider } from '@apollo/client';
import { useQuery } from '@apollo/client';
import client from '../../lib/apolloClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GENERATE_BIO = gql`
  mutation GenerateBio {
    generateAiBio
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
    fontSize: '14px',
    color: '#555',
    backgroundColor: '#f3f3f3',
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tips: {
    marginTop: theme.spacing(2),
    color: theme.palette.success.main,
  },
  inputAdornment: {
    float: 'left',
    position: 'absolute',
    right: 0,
    bottom: '14px',
  },
}));

const AIBioFunctional = (props) => {
  const classes = useStyles();
  const [bio, setBio] = useState(props.bio || '');
  const [generateBio] = useMutation(GENERATE_BIO, {
    onCompleted: (data) => {
      console.log('data', data);
      setBio(data.generateAiBio);
    },
    onError: (error) => {
      console.error('Error generating bio:', error);
    }
  });

  const handleGenerate = () => {
    generateBio();
  };

  const handleRegenerate = () => {
    generateBio();
  };

  return (
   <Paper className={classes.root}>
      <div style={{ position: 'relative' }}>
        <TextField
          className={classes.textField}
          multiline
          minRows={8}
          variant="outlined"
          value={bio}
          name="user[bio]"
          onChange={(e) => setBio(e.target.value)}
          placeholder="Your professional bio"
          InputProps={{
            className: classes.textField,
          }}
        />
        <InputAdornment position="end" className={classes.inputAdornment}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<i className="fa-solid fa-robot"></i>}
            onClick={handleGenerate}
            size="small"
            className={classes.generateButton}
          >
            Generate with AI
          </Button>
        </InputAdornment>
      </div>
    </Paper>
  );
};

const AIBio = (props, context) => {
  return (
    <ApolloProvider client={client}>
      <AIBioFunctional {...props} />
    </ApolloProvider>
  );
}
export default AIBio;
