import React, { useEffect, useState } from 'react';
import TeamRequest from '../components/TeamRequest/TeamRequest';
import client from '../lib/apolloClient';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client'; 
import { get } from 'lodash';

const GET_TEAM_REQUEST = gql`
  query TeamRequest($id: ID!) {
    teamRequest(id: $id) {
      id
      teamHeadline
      teamDescription
      user {
        id
        user_name
        name
        profile_picture
        gross_production
        assets_under_management
        number_of_clients
        original_gross_production
        original_assets_under_management
        original_number_of_clients
        __typename
      }
      status
      createdAt
      updatedAt
      teamRequestItems {
        id
        email
        userDisapproved
        onAnotherTeam
        user {
          id
          user_name
          name
          profile_picture
          gross_production
          assets_under_management
          original_gross_production
          original_assets_under_management
          original_number_of_clients
          number_of_clients
          inventory_user {
            id
            catestrophic_plan_on_file
            __typename
          }
          __typename
        }
        approved
        __typename
      }
      team {
        id
        __typename
      }
      __typename
    }
  }
`;

const TeamRequestContainer = ({ match }) => {
  const [teamRequest, setTeamRequest] = useState(null);
  const { loading, error, data } = useQuery(GET_TEAM_REQUEST, {
    variables: { id: match.params.id },
    client,
  });

  if(loading) {
    return <div>Loading...</div>;
  }
  if(error) {
    return <div>{error.message}</div>;
  }

  return <TeamRequest teamRequest={data?.teamRequest} />;
};

export default TeamRequestContainer;
