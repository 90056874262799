import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import SignUpWizard from '../components/SignUpWizard/SignUpWizard';
import * as actions from '../actions/userActionCreators';

const SignUpQueries = gql`
  query SignUpQueries {
    currentUserInfoAuth
    currentUserASubscriber
    company {
      is_managed
      explicit_opt_in_tos_and_pp
      country
      supported_locales
      agent_word
      has_billing
      business_type
      has_agency_name
      has_county
      ask_transition_readiness_on_signup
      skip_product_mix_on_signup
      hide_product_mix_on_profile
      ask_me_about_mandatory
      stylesheet_name
      show_planning_preference_question
      branded_name
      logo
      ask_me_about_question
      imported_production_data_read_only
      use_birth_year_instead_of_dob
      iiroc_licensing_options
      use_canadian_retail_wealth_product_mix
      use_p_and_c_product_mix
      planning_preference_already_exists_question
      catastrophic_planning_word
      request_aum_on_sutd
      allow_user_affiliations
      imported_production_data_read_only
      enabled_student_profiles
      student_word
      student_program_title
      features
      business_continuity_states {
        id
        name
      }
      succession_planning_states {
        id
        name
      }
      identity_authentication_enabled
      opt_in_scroll_accept_tos_privacy_policy
    }
    getCurrentUser {
      id
      email
      name
      postal_code
      user_name
      agency_name
      annual_revenue
      assets_under_management
      bio
      birth_date
      city
      county
      number_of_clients
      profile_picture
      province
      recurring_revenue
      business_continuity_state_id
      succession_planning_state_id
      created_at
      fee_based_revenue
      trailer_revenue
      direct_revenue
      transactional_revenue
      planning_preference
      completed_step_1
      is_branch_exception
      talent_acquisition_user
      affiliations { 
        id
        name
      }
      company {
        is_managed
        explicit_opt_in_tos_and_pp
        skip_product_mix_on_signup
        hide_product_mix_on_profile
        branded_name
        identity_authentication_enabled
        business_type
        use_canadian_retail_wealth_product_mix
        use_p_and_c_product_mix
        planning_preference_already_exists_question
        enabled_student_profiles
        student_word
        student_program_title
        catastrophic_planning_word
        imported_production_data_read_only
      }
      headline
      avg_client_age
      nick_name
      is_student
      student_url_video
      student_url_resume
      student_url_linkedin
      student_program_year
      roles
      designations
      tag_list
      transition_goals
      advisor_licenses
      in_auction
      years_practicing
      number_of_employees
      percent_revenue_recurring
      percent_non_recurring_revenue
      percent_fee_based
      percent_commission
      gross_production
      mix_insurance
      mix_mutual_funds
      mix_annuities
      mix_other
      postal_code
      email
      locale
      motivation
      recognition_level
      product_mix {
        life_ifp
        home_ifp
        auto_ifp
        commercial_ifp
        health_ifp
        group_ifp
        mutual_funds_aum
        securities_aum
        annuities_aua
        segregated_funds_aua
        total_assets
        mcpi_ifp
        private_products_ifp
        crop_hail_ifp
        crop_hail_with_wind_ifp
        farm_insurance_ifp
        price_products_ifp
        replant_supplement_ifp
        gic_fixed_income
        insurance
        equity
      }
      headline_completed
      personal_attributes_complete
      practice_complete
      compensation_complete
      product_mix_complete
      transition_goals_complete
      revenue_complete
      planning_info_complete
      transition_period_months
      target_timeframe
      workweek_trajectory_now
      workweek_trajectory_year_3
      workweek_trajectory_year_5
      workweek_trajectory_year_10
      workweek_trajectory_year_15
      workweek_trajectory_year_20
      workweek_trajectory_year_25
      inventory_user {
        id
        recognition_level
        is_invisible
      }
    }
    availableAvatars
    availableDesignations {
      id
      name
      fr_name
    }
    availableProducts
    availableStrengths
    availableAdvisorLicenses
    getPrivacyPolicy
    getTermsOfService
    availableAffiliations {
      id
      name
    }
  }
`;

const SignUpContainer = graphql(SignUpQueries)(SignUpWizard);

const mapStateToProps = state => ({ user: state.user, intl: state.intl });

export default connect(mapStateToProps, actions)(SignUpContainer);
