import React, { useState } from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import TeamMemberRow from "./TeamMemberRow";
import { GroupAdd, Save, Edit } from "@material-ui/icons";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import client from '../../lib/apolloClient';
import NewTeamMember from "./NewTeamMember";

const UPDATE_TEAM = gql`
  mutation UpdateTeam($id: ID!, $name: String!) {
    updateTeam(id: $id, name: $name) {
      id
      name
      teamHeadline
      teamDescription
      teamRequest {
        id
      }
      teamMembers {
        id
        user {
          id
          name
          gross_production
          original_gross_production
          assets_under_management
          number_of_clients

        }
      }
    }
  }`;

const Team = ({ team, setTeam, classes }) => {
  const [updateTeam] = useMutation(UPDATE_TEAM, { client });
  const [name, setName] = useState(team.name);
  const [editing, setEditing] = useState(false);
  const teamMembersIncludingUser = team.teamMembers;
  const totalApprovedGrossProduction = teamMembersIncludingUser.reduce((acc, teamMember) => acc + teamMember.user.original_gross_production, 0);
  const totalApprovedAUM = teamMembersIncludingUser.reduce((acc, teamMember) => acc + teamMember.user.original_assets_under_management, 0);
  const totalApprovedClients = teamMembersIncludingUser.reduce((acc, teamMember) => acc + teamMember.user.original_number_of_clients, 0);
  const teamHeadline = team.teamHeadline;
  const teamDescription = team.teamDescription;

  return (
    <Box sx={{ p: 2 }} style={{overflow: 'scroll', height: 'calc(100vh - 100px)'}}>
      <Box sx={{ mb: 3 }}>
        <h2>
          {editing ? <> 
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            <Save onClick={() => {
              setEditing(false);
              updateTeam({ variables: { id: team.id, name } }).then((result) => {
                setTeam(result.data.updateTeam);
              });
            }} />
          </>: <> {team.name} <Edit onClick={ () => setEditing(true) } /> </> }
        </h2>
        <a 
          className={classes.teamRequestLink}
          href={`/admin/team_requests/${team.teamRequest?.id}`}>
            <GroupAdd /> <span className={classes.linkText}>View Original Team Request</span>
        </a>
      </Box>
      <Box sx={{ mb: 3 }}>
        <h5>Team Headline: {teamHeadline}</h5>
        <h5>Team Description: {teamDescription}</h5>
        <h4>Team Members</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Gross Production</TableCell>
              <TableCell>AUM</TableCell>
              <TableCell>Clients</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(teamMembersIncludingUser || []).map((teamMember, index) => (
              <TeamMemberRow 
                key={teamMember.id} 
                teamMember={teamMember} 
                updateTeamMembers={() => {
                  team.teamMembers.splice(index, 1);
                  setTeam({ ...team });
                }} 
                isUser={false}
                />
            ))}
            <NewTeamMember team={team} updateTeamMembers={
              (newTeamMember) => {
                setTeam({ ...team, teamMembers: [...team.teamMembers, newTeamMember] });
              }
            }/>
            <TableRow className={classes.bottomRow}>
              <TableCell className={classes.bottomRowCell}>Totals</TableCell>
              <TableCell className={classes.bottomRowCell}>{numeral(totalApprovedGrossProduction).format('$0,0')}</TableCell>
              <TableCell className={classes.bottomRowCell}>{numeral(totalApprovedAUM).format('$0,0')}</TableCell>
              <TableCell className={classes.bottomRowCell}>{totalApprovedClients}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

const styles = theme => ({
  bottomRow: {
    backgroundColor: '#f3f3f3'
  },
  bottomRowCell: {
    fontWeight: 'bold'
  },
  teamRequestLink: {
    color: '#007bff',
    textDecoration: 'none',
    float: 'right',
    marginTop: '10px'
  },
  linkText: {
    verticalAlign: 'super',
    marginLeft: '2px',
  }
});

export default withStyles(styles)(Team);