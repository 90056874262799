import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import { graphql, Query } from 'react-apollo';
import { isEmpty, get } from 'lodash';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CalibrationsIcon from '@material-ui/icons/CenterFocusStrong';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import initials from 'initials';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PandLTab from './PandLTab';
import OverviewUserData from './OverviewUserData';
import OverviewInventoryUserData from './OverviewInventoryUserData';
import ManagersAssessment from './ManagersAssessment';
import ApproveUserDialog from './ApproveUserDialog';
import InviteButton from '../InviteButton/InviteButton';
import { S3Image } from '../../lib/s3';
import { bgColorForName } from '../../lib/findBobUtils';
import PipelineCardTabContainer from '../../containers/PipelineCardTabContainer';
import ActivityTabContainer from '../../containers/ActivityTabContainer';
import BenchCard from './BenchCard';
import BenchNomCard from './BenchNomCard';
import DeleteUserDialog from './DeleteUserDialog';
import ManagerTokenInfo from './ManagerTokenInfo';
import FeatureTab from './FeatureTab';
import { flowRight as compose } from 'lodash';
import moment from 'moment';
import DownloadCatPlanButton from './DownloadCatPlanButton';
import CashFlowAnalysisWidgit from './CashFlowAnalysisWidgit';

const BenchQuery = gql`
  query BenchQuery($id: ID!) {
    getBenchUsers(inventory_user_id: $id) {
      nominee_iu {
        id
        name
        has_user
        profile_picture
      }
      inventory_user {
        id
        name
      }
      nominate_as
      ready_in
    }
  }
`;

const DeleteUser = gql`
  mutation DeleteUser($inventory_user_id: ID!) {
    deleteUser(inventory_user_id: $inventory_user_id)
  }
`;

const ApproveUser = gql`
  mutation ApproveUser($inventory_user_id: ID!) {
    approveUser(inventory_user_id: $inventory_user_id)
  }
`;

class ShowInventoryUserDrawer extends React.Component {
  constructor (props) {
    super(props);
    const { inventoryUser } = this.props;
    const user = get(inventoryUser, 'user', []);
    this.state = {
      nomCardOpen         : false,
      tabValue            : 0,
      showHidden          : false,
      manager_email       : inventoryUser.manager_email,
      open1               : isEmpty(user) || get(inventoryUser, 'user.deleted', false),
      open2               : !isEmpty(user),
      open3               : true,
      open4               : true,
      openDeleteUserCard  : false,
      openApproveUserCard : false,
    };
  }

  componentWillReceiveProps (nextProps) {
    const { inventoryUser } = this.props;
    if (inventoryUser.id !== nextProps.inventoryUser.id) {
      const user = get(nextProps.inventoryUser, 'user', []);
      this.setState({
        ...nextProps.inventoryUser,
        showHidden : false,
        open1      : isEmpty(user) || nextProps.inventoryUser.user.deleted,
        open2      : !isEmpty(user),
        open3      : true,
        open4      : true,
      });
    }
  }

  handleDelete = () => {
    const { DeleteUser, inventoryUser, updateAction } = this.props;
    DeleteUser({
      variables : {
        inventory_user_id : parseInt(inventoryUser.id, 10),
      },
    })
      .then(r => {
        if (r.data.deleteUser === true) {
          updateAction({
            user : {
              ...inventoryUser,
              ...this.state,
              user : { deleted: true, hidden: true },
            },
          });
          this.setState({ open1: true });
        }
        // Reload the inventory user card so reflect changes
        this.setState({ openDeleteUserCard: false });
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  handleApprove = () => {
    const { ApproveUser, inventoryUser, updateAction, updateInventoryUser, user } = this.props;
    ApproveUser({
      variables : {
        inventory_user_id : parseInt(inventoryUser.id, 10),
      },
    })
      .then(r => {
        if (r.data.approveUser === true) {
          updateAction({
            user : {
              ...inventoryUser,
              ...this.state,
              user : { ...inventoryUser.user, approval_state: 'approved' },
            },
          });
          updateInventoryUser({
            ...inventoryUser,
            approval_state : 'approved',
            user           : { ...inventoryUser.user, approval_state: 'approved' },
          });
        }
        this.setState({ openApproveUserCard: false });
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  handleClickOpen = () => {
    this.setState({ nomCardOpen: true });
  };

  handleClose = () => {
    this.setState({ nomCardOpen: false });
  };

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  handleOpen = name => {
    const open = this.state[name];
    this.setState({ [name]: !open });
  };

  openDeleteCard = () => {
    this.setState({ openDeleteUserCard: true });
  };

  closeDeleteCard = () => {
    this.setState({ openDeleteUserCard: false });
  };

  openApproveCard = () => {
    this.setState({ openApproveUserCard: true });
  };

  closeApproveCard = () => {
    this.setState({ openApproveUserCard: false });
  };

  returnDesignations = () => {
    const { classes, inventoryUser } = this.props;
    const designations = get(inventoryUser, 'user.designations', false);
    if (designations) {
      return <span className={classes.designations}>{designations.join(', ')}</span>;
    }
    return <div />;
  };

  callUpdateUser = () => {
    const { updateAction } = this.props;
    const delayedUpdate = setTimeout(() => {
      updateAction({ user: {} });
    }, 1000);
    return delayedUpdate;
  };

  render () {
    const {
      agentWordForCompany,
      assignManager,
      classes,
      country,
      inventoryUser,
      inventoryUsers,
      inventory_users,
      loadBenchUsers,
      managers,
      openSnack,
      selectLink,
      showSelectButton,
      showActivities,
      showRelated,
      showFeatures,
      updateAction,
      updateInventoryUser,
      user,
      currentUserIsAdmin,
      currentUserId,
      refetch,
      showBench,
      onlyUsers,
      tags,
      agencies,
      allFeatures,
      company,
    } = this.props;
    const { nomCardOpen, tabValue, open1, open2, open3, open4, openDeleteUserCard, openApproveUserCard } = this.state;
    const showApproveButton =
      get(inventoryUser, 'user.approval_state', '') === 'pending' &&
      get(company, 'business_type') === 'wealth_rj' &&
      currentUserIsAdmin;
    return (
      <div>
        <div className={classes.header}>
          {inventoryUser.hidden &&
          !inventoryUser.user.deleted && (
            <div>
              <Chip
                label="DELETE USER"
                style={{
                  color      : 'white',
                  background : '#dc3545',
                  margin     : '15px 0 0 40%',
                  position   : 'absolute',
                }}
                onClick={() => this.openDeleteCard()}
              />
            </div>
          )}
          <DeleteUserDialog
            open={openDeleteUserCard}
            deleteUser={() => this.handleDelete()}
            closeDeleteCard={() => this.closeDeleteCard()}
          />
          <ApproveUserDialog
            open={openApproveUserCard}
            approveUser={() => this.handleApprove()}
            closeApproveCard={() => this.closeApproveCard()}
          />
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={() => {
              this.callUpdateUser();
            }}>
            <CloseIcon />
          </IconButton>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Avatar
                size="24"
                name={inventoryUser.name}
                src={inventoryUser.has_user ? S3Image(inventoryUser.profile_picture) : null}
                className={classes.avatar}
                style={{ backgroundColor: bgColorForName(inventoryUser.name) }}>
                <span className={classes.avatarText}>{initials(inventoryUser.name)}</span>
              </Avatar>
            </Grid>
            <Grid item xs={7}>
              <h3>
                <span style={{ verticalAlign: 'middle' }}>{inventoryUser.name}</span>
                <br />
                {this.returnDesignations()}
              </h3>
            </Grid>
            <Grid item xs={3}>
              {showApproveButton && (
                <button style={{ marginTop: 20 }} onClick={() => this.openApproveCard()}>
                  Approve User
                </button>
              )}
            </Grid>
          </Grid>
          <Tabs className={classes.tab} value={tabValue} onChange={this.handleTabChange} centered>
            <Tab label="Overview" className={classes.tabRoot} value={0} />
            <Tab label="Details" className={classes.tabRoot} value={1} />
            {showActivities && <Tab label="Activity" className={classes.tabRoot} value={2} />}
            {showRelated && <Tab label="Related" className={classes.tabRoot} value={3} />}
            {currentUserIsAdmin && showFeatures && <Tab label="Features" className={classes.tabRoot} value={4} />}
          </Tabs>
        </div>
        <div className={classes.container}>
          <Grid container spacing={8} justify="center" alignItems="center">
            <Grid item xs={12} className={classes.marketplacebutton}>
              <InviteButton
                type={inventoryUser.marketplace_status}
                inviteeId={inventoryUser.id}
                inviteeName={inventoryUser.name}
                inventory_users={inventory_users}
                updateInventoryUser={updateInventoryUser}
                updateAction={updateAction}
              />
            </Grid>
          </Grid>
          {tabValue === 0 && (
            <div>
              {!onlyUsers && (
                <div>
                  <ExpansionPanel expanded={open1}>
                    <ExpansionPanelSummary
                      onClick={() => this.handleOpen('open1')}
                      expandIcon={<ExpandMoreIcon />}
                      classes={{
                        root : classes.root,
                      }}>
                      <ListItemIcon>
                        <i className="material-icons">person</i>
                      </ListItemIcon>
                      <Typography className={classes.heading}>Enterprise Supplied</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <OverviewInventoryUserData
                        inventoryUser={inventoryUser}
                        user={user}
                        managers={managers}
                        assignManager={assignManager}
                        openSnack={openSnack}
                        updateInventoryUser={updateInventoryUser}
                        agentWordForCompany={agentWordForCompany}
                        updateAction={updateAction}
                        country={country}
                        company={company}
                        agencies={agencies}
                        refetch={refetch}
                      />
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                      <ManagerTokenInfo user={inventoryUser} approvalState={get(user, 'approval_state')} />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              )}
              {get(inventoryUser, 'user.deleted', false) && (
                <div style={{ background: '#F1948A' }}>
                  <ExpansionPanel disabled expanded={open2}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      classes={{
                        root : classes.root,
                      }}>
                      <ListItemIcon>
                        <i className="material-icons">person</i>
                      </ListItemIcon>
                      <Typography className={classes.heading}>User has been Deleted</Typography>
                    </ExpansionPanelSummary>
                  </ExpansionPanel>
                </div>
              )}
              {!get(inventoryUser, 'user.deleted', false) && (
                <div>
                  <ExpansionPanel disabled={isEmpty(user)} expanded={open2}>
                    <ExpansionPanelSummary
                      onClick={() => this.handleOpen('open2')}
                      expandIcon={<ExpandMoreIcon />}
                      classes={{
                        root : classes.root,
                      }}>
                      <ListItemIcon>
                        <i className="material-icons">person</i>
                      </ListItemIcon>
                      <Typography className={classes.heading}>
                        {user ? (
                          'User Supplied'
                        ) : (
                          'User will be displayed when an [agent/advisor] creates their marketplace profile'
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        {user && (
                          <OverviewUserData
                            inventoryUser={inventoryUser}
                            user={user}
                            managers={managers}
                            assignManager={assignManager}
                            openSnack={openSnack}
                            updateInventoryUser={updateInventoryUser}
                            agentWordForCompany={agentWordForCompany}
                            updateAction={updateAction}
                            company={company}
                          />
                        )}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              )}
              {!onlyUsers && (
                <div>
                  <ExpansionPanel expanded={open3}>
                    <ExpansionPanelSummary
                      onClick={() => this.handleOpen('open3')}
                      expandIcon={<ExpandMoreIcon />}
                      classes={{
                        root : classes.root,
                      }}>
                      <ListItemIcon>
                        <i class="material-icons">assessment</i>
                      </ListItemIcon>
                      <Typography className={classes.heading}>Manager's Assessment</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        <ManagersAssessment
                          inventoryUser={inventoryUser}
                          user={user}
                          managers={managers}
                          assignManager={assignManager}
                          openSnack={openSnack}
                          updateInventoryUser={updateInventoryUser}
                          agentWordForCompany={agentWordForCompany}
                          updateAction={updateAction}
                          country={country}
                          refetch={refetch}
                          tags={tags}
                          company={company}
                        />
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              )}
              <div>
                {showBench && (
                  <ExpansionPanel expanded={open4}>
                    <ExpansionPanelSummary
                      onClick={() => this.handleOpen('open4')}
                      expandIcon={<ExpandMoreIcon />}
                      classes={{
                        root : classes.root,
                      }}>
                      <ListItemIcon>
                        <i class="material-icons">group_add</i>
                      </ListItemIcon>
                      <Typography className={classes.heading}>Bench</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div style={{ width: '100%' }}>
                        <hr />
                        <div className={classes.benchBar}>
                          <b className={classes.title}>{inventoryUser.name}&apos;s Bench</b>
                          <Button variant="outlined" className={classes.button} onClick={this.handleClickOpen}>
                            <b>+ Add</b>
                          </Button>
                          <span className={classes.button}>&nbsp;</span>
                          <Button
                            variant="outlined"
                            className={classes.button}
                            onClick={() => {
                              window.location.href = `/admin/opportunities/new?inventoryUserID=${inventoryUser.id}`;
                            }}>
                            <b>Post Bob</b>
                          </Button>
                        </div>
                        <hr />
                        <div>
                          <Query query={BenchQuery} variables={{ id: inventoryUser.id }} fetchPolicy="network-only">
                            {({ loading, error, data }) => {
                              if (loading) return <div />;
                              if (error) return `Error!: ${error}`;
                              if (
                                data.getBenchUsers &&
                                data.getBenchUsers.length > 0 &&
                                isEmpty(inventoryUser.bench) &&
                                !inventoryUser.benchLoad
                              ) {
                                loadBenchUsers(data.getBenchUsers, true);
                                return <div />;
                              }
                              if (!isEmpty(inventoryUser.bench)) {
                                return (
                                  <div>
                                    {inventoryUser.bench.map(benchUser => (
                                      <div className={classes.bench} key={get(benchUser, 'nominee_iu.id', '')}>
                                        <BenchCard
                                          inventoryUser={inventoryUser}
                                          loadBenchUsers={loadBenchUsers}
                                          user={benchUser.nominee_iu}
                                          updateAction={updateAction}
                                          bench
                                          nominate_as={benchUser.nominate_as}
                                          ready_in={benchUser.ready_in}
                                          updateInventoryUser={updateInventoryUser}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                );
                              }
                              return (
                                <p>
                                  Please add user&apos;s to {inventoryUser.name}
                                  &apos;s bench.
                                </p>
                              );
                            }}
                          </Query>
                        </div>
                        <BenchNomCard
                          open={nomCardOpen}
                          handleClose={this.handleClose}
                          inventoryUser={inventoryUser}
                          inventoryUserBenchList={inventoryUser.bench}
                          inventoryUsers={inventoryUsers}
                          updateAction={updateAction}
                          loadBenchUsers={loadBenchUsers}
                          updateInventoryUser={updateInventoryUser}
                        />
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )}
              </div>
            </div>
          )}
          {tabValue === 1 && (
            <PandLTab
              inventoryUser={inventoryUser}
              agentWordForCompany={agentWordForCompany}
              onlyUsers={onlyUsers}
              user={user}
              managers={managers}
              assignManager={assignManager}
              openSnack={openSnack}
              updateInventoryUser={updateInventoryUser}
              updateAction={updateAction}
              country={country}
              refetch={refetch}
              tags={tags}
              company={company}
            />
          )}
          {tabValue === 2 &&
          showActivities && (
            <ActivityTabContainer
              inventoryUser={inventoryUser}
              updateAction={updateAction}
              currentUserIsAdmin={currentUserIsAdmin}
              currentUserId={currentUserId}
            />
          )}
          {tabValue === 3 &&
            showRelated && (
            <div>
              {inventoryUser.cat_plan_filename && <DownloadCatPlanButton inventoryUser={inventoryUser} />}
              {inventoryUser.calibration_sessions.length > 0 && (
                <div>
                <h5>Previous Calibration Sessions</h5>
                {inventoryUser.calibration_sessions.map(calibrationSession => (
                  <div>
                    <a href={`/admin/calibration_sessions/${calibrationSession.id}`}><CalibrationsIcon /> {calibrationSession.name}</a>
                  </div>
                ))}
              </div>
              )}
              <div>
                <h5>Valuations (DCF)</h5>
                {
                    <div>
                      <table style={{width: '100%'}}>
                        <tr>
                          <th>Name</th>
                          <th>Author</th>
                          <th>Created At</th>
                          <th>Link</th>
                        </tr>
                        {[...inventoryUser.discounted_cash_flows].reverse().map(dcf => (
                          <tr>
                            <td>{get(dcf, 'name')}</td>
                            <td>{get(dcf, 'author.name')}</td>
                            <td>{moment(dcf.createdAt).format("D MMM, YYYY")}</td>
                            <td><a href={`/valuations/dcf?dcf_id=${dcf.friendlyName}`} target="_blank">View</a></td>
                          </tr>
                        ))}
                      </table>
                    </div>
                }
              </div>
              <br />
              <CashFlowAnalysisWidgit inventoryUser={inventoryUser} />
              <PipelineCardTabContainer inventoryUserId={inventoryUser.id} documents={inventoryUser.documents} />
            </div>
          )}
          {tabValue === 4 &&
          currentUserIsAdmin &&
          showFeatures && (
            <FeatureTab
              user={inventoryUser.user}
              allFeatures={allFeatures}
              featureTypes={inventoryUser.feature_types}
              updateInventoryUser={updateInventoryUser}
              inventoryUser={inventoryUser}
              currentUserIsAdmin={currentUserIsAdmin}
            />
          )}
          <br />

          {showSelectButton && (
            <div className={classes.buttonList}>
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  updateAction({});
                }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.close}
                component={props => <Link to={selectLink} {...props} />}>
                Select
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ShowInventoryUserDrawer.propTypes = {
  inventoryUser       : PropTypes.shape({
    name           : PropTypes.string,
    birth_date     : PropTypes.string,
    province       : PropTypes.string,
    rev_yield      : PropTypes.number,
    clients        : PropTypes.number,
    total_premium  : PropTypes.number,
    total_assets   : PropTypes.number,
    key_asset      : PropTypes.bool,
    risk_of_loss   : PropTypes.string,
    impact_of_loss : PropTypes.string,
  }),
  selectLink          : PropTypes.string.isRequired,
  updateAction        : PropTypes.func,
  inventoryUsers      : PropTypes.object.isRequired,
  classes             : PropTypes.object.isRequired,
  inventory_users     : PropTypes.object.isRequired,
  showSelectButton    : PropTypes.bool,
  showBench           : PropTypes.bool,
  loadBenchUsers      : PropTypes.func.isRequired,
  refetch             : PropTypes.func.isRequired,
  showActivities      : PropTypes.bool,
  showRelated         : PropTypes.bool,
  showFeatures        : PropTypes.bool,
  onlyUsers           : PropTypes.bool,
  mutate              : PropTypes.func.isRequired,
  agentWordForCompany : PropTypes.string.isRequired,
  country             : PropTypes.string.isRequired,
  assignManager       : PropTypes.bool.isRequired,
  managers            : PropTypes.array.isRequired,
  openSnack           : PropTypes.bool.isRequired,
  updateInventoryUser : PropTypes.func.isRequired,
  user                : PropTypes.object.isRequired,
  currentUserIsAdmin  : PropTypes.bool.isRequired,
  tags                : PropTypes.array.isRequired,
  agencies            : PropTypes.array.isRequired,
  currentUserId       : PropTypes.number.isRequired,
};

ShowInventoryUserDrawer.defaultProps = {
  inventoryUser    : {},
  showSelectButton : false,
  showBench        : false,
  showActivities   : true,
  showRelated      : true,
  showFeatures     : true,
  updateAction     : () => {},
  onlyUsers        : false,
};

const styles = {
  drawerPaper       : {
    width        : 530,
    position     : 'absolute',
    top          : 68,
    borderRadius : 4,
  },
  container         : {
    height    : '100%',
    padding   : 10,
    marginTop : 180,
  },
  header            : {
    width        : 530,
    height       : 64,
    borderBottom : '1px solid #eee',
    position     : 'fixed',
    zIndex       : 100,
    background   : 'white',
  },
  title             : {
    padding : 4,
    float   : 'left',
  },
  button            : {
    float : 'right',
  },
  full              : {
    width : '100%',
  },
  benchBar          : {
    height : 28,
  },
  bench             : { marginBottom: 1 },
  data              : { padding: 4 },
  clear             : { clear: 'both' },
  buttonList        : { margin: '200px 0 0 0' },

  text              : {
    textAlign : 'right',
  },
  prodmixborder     : {
    border       : '1px solid lightgrey',
    borderRadius : 8,
    margin       : 5,
    height       : 310,
    width        : 230,
  },
  prodmixtitle      : {
    fontWeight : 'bold',
    textAlign  : 'center',
    margin     : 7,
  },
  prodmixcontent    : {
    textAlign : 'center',
    margin    : 7,
  },
  chip              : {
    margin : 2,
    height : 23,
  },
  tab               : {
    marginBottom : 10,
    width        : '100%',
  },
  tabRoot           : {
    minWidth : 100,
  },
  root              : {
    color      : 'black',
    background : '#F6F6F6',
    '&:hover'  : { background: '#E2E2E2' },
  },
  marketplacebutton : {
    textAlign : 'center',
    margin    : '10px 0px 10px 0px',
  },
  avatar            : {
    margin   : 10,
    width    : 60,
    height   : 60,
    fontSize : 20,
  },
  deletedHeading    : {
    margin   : 10,
    width    : 60,
    height   : 60,
    fontSize : 20,
  },
};

export default withStyles(styles)(
  compose(
    graphql(DeleteUser, {
      name : 'DeleteUser',
    }),
    graphql(ApproveUser, {
      name : 'ApproveUser',
    })
  )(ShowInventoryUserDrawer)
);
