import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { get, startCase } from 'lodash';
import InventoryUsers from '../components/InventoryUsers/InventoryUsers';
import * as actions from '../actions/inventoryUsersActionCreators';
import * as marketplaceActions from '../actions/marketplaceStatusActionCreators';
import * as bulkEditUserActions from '../actions/bulkEditItemsActionCreators';
import * as userActions from '../actions/userActionCreators';
import { fb_columns, filteredInvUserColumns } from '../components/InventoryUsers/InventoryUserColumns';

const InventoryUserQueries = gql`
  query InventoryUserQueries(
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    $filterList: InventoryUserFilterInput
    $nameSearch: String
    $distanceSearch: String
    $distanceWithin: Int
    $tgFilter: String
    $tagFilter: String
  ) {
    getInventoryUsers(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      filterList: $filterList
      nameSearch: $nameSearch
      distanceSearch: $distanceSearch
      distanceWithin: $distanceWithin
      tgFilter: $tgFilter
      tagFilter: $tagFilter
    ) {
      id
      name
      email
      birth_date
      phone_number
      city
      province
      affiliation
      division
      branch
      website
      licensing
      rev_yield
      clients
      total_premium
      total_assets
      key_asset
      is_invisible
      recurring_revenue
      risk_of_loss
      impact_of_loss
      risk_of_loss_num
      impact_of_loss_num
      marketplace_status
      start_date
      has_user
      reason_for_loss
      manager_email
      transition_goals
      is_customer
      is_obfuscated
      profile_picture
      life_ifp
      home_ifp
      auto_ifp
      commercial_ifp
      health_ifp
      group_ifp
      mutual_funds_aum
      securities_aum
      annuities_aua
      segregated_funds_aua
      total_assets
      mcpi_ifp
      private_products_ifp
      crop_hail_ifp
      crop_hail_with_wind_ifp
      farm_insurance_ifp
      price_products_ifp
      replant_supplement_ifp
      limited_access
      hidden
      approval_state
      is_exception
      in_good_standing
      remote_id
      series_7
      series_63
      series_65
      series_66
      user_motivation
      insurance_business
      planning_preference
      outstanding_load_bonus_information
      alex_brown_flag
      region
      catestrophic_plan_on_file
      recognition_level
      industry_length_of_service
      amp_trainee_status
      office_city
      office_state
      office_postal_code
      discretion
      licensing
      retirement_choice_calculator_code
      can_match
      can_create_meeting_room
      is_student
      segmented_view
      market_value
      market_value_percentile
      pct_client_0to18
      pct_client_18to34
      pct_client_35to50
      pct_client_51to70
      pct_client_71plus
      cssid
      transactional_revenue
      direct_revenue
      trailer_revenue
      fee_based_revenue
      manager_remote_id
      heightened_supervision
      series_7
      series_63
      series_65
      series_66
      state_registrations
      team_size
      team_name
      team_enhanced_compensation
      gross_production_2023
      gross_production_2022
      gross_production_2021
      gross_production_2020
      gross_production_2019
      gross_production_2018
      gross_production_2017
      gross_production_2016
      total_assets_2023
      total_assets_2022
      total_assets_2021
      total_assets_2020
      total_assets_2019
      total_assets_2018
      total_assets_2017
      total_assets_2016
      greater_fifty_percent_revenue_top_twenty
      fifty_percent_clients_aged_65_or_older
      bottom_two_tiers_recurring_revenue
      nna_for_the_year
      br_gross_production_2023
      br_gross_production_2022
      br_gross_production_2021
      br_gross_production_2020
      br_gross_production_2019
      br_gross_production_2018
      br_gross_production_2017
      br_gross_production_2016
      br_total_assets_2023
      br_total_assets_2022
      br_total_assets_2021
      br_total_assets_2020
      br_total_assets_2019
      br_total_assets_2018
      br_total_assets_2017
      br_total_assets_2016
      br_greater_fifty_percent_revenue_top_twenty
      br_fifty_percent_clients_aged_65_or_older
      br_bottom_two_tiers_recurring_revenue
      br_nna_for_the_year
      is_branch_manager
      is_branch_exception
      no_named_successor
      br_practice_size
      br_production
      br_transactional_revenue
      br_fee_based_revenue
      br_trails_revenue
      br_directs_revenue
      average_age
      is_rjas
      is_complex_manager
      is_fid
      is_bank_program_manager
      successor1_name
      agreement_type_for_for_catastrophic_plan
      catastrophic_plan_contract_date
      br_average_age
      br_number_of_relationships
      divisional_director_name
      divisional_director_email
      divisional_director_cssid
      asst_divisional_director1_name
      asst_divisional_director1_email
      asst_divisional_director1_cssid
      asst_divisional_director2_name
      asst_divisional_director2_email
      asst_divisional_director2_cssid
      emplid
      successor1_emplid
      successor2_emplid
      successor3_emplid
      successor4_emplid
      successor5_emplid
      manager_supervisor_info_emplid
      agreement_date
      catastrophic_plan_contract_date
      approvers
      updated_at
      created_at
      minimum_profile_completed
      approve_user_on_create
      hide_on_create
      customer_on_create
      three_yr_avg_t12
      talent_acquisition_user
      company {
        id
        business_type
      }
      iu_tags {
        id
        name
        __typename
      }
      bench_count
      manager {
        id
        name
        email
        has_user
        profile_picture
        __typename
      }
      user {
        id
        annual_revenue
        recurring_revenue
        hidden
        talent_acquisition_user
        profile_percentage_complete
        created_at    
        product_mix {
          id
          aum_stars
          p_c_stars
          group_stars
          health_and_life_stars
          life_ifp
          home_ifp
          auto_ifp
          commercial_ifp
          health_ifp
          group_ifp
          mutual_funds_aum
          securities_aum
          annuities_aua
          segregated_funds_aua
          total_assets
          mcpi_ifp
          private_products_ifp
          crop_hail_ifp
          crop_hail_with_wind_ifp
          farm_insurance_ifp
          price_products_ifp
          replant_supplement_ifp
          gic_fixed_income
          insurance
          equity
          __typename
        }
        __typename
      }
      __typename
    }
    getInventoryUserCount(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      filterList: $filterList
      nameSearch: $nameSearch
    )
    countryForCompany
    getCurrentUser {
      id
      company {
        id
        match_pool_exception_enabled
        affiliate_name
        business_type
        hidden_inventory_user_columns
        country
        add_age_column_to_tables
        catastrophic_planning_word
        feature_types
        __typename
      }
      __typename
    }
    currentUserIsAdmin
    currentUserId
    getInventoryUserDefaultColumnNames
    allFeatures
    getInventoryUserTags {
      id
      name
      __typename
    }
    getAgencies {
      id
      name
      __typename
    }
    agentWordForCompany
    getManagers {
      id
      email
      name
      __typename
    }
  }
`;

const InventoryUserContainer = props => {
  const { inventory_users, loadInventoryUserList, bulkEditItems } = props;
  const queryVars = {
    offset  : inventory_users.page * inventory_users.rowsPerPage || 0,
    limit   : inventory_users.rowsPerPage || 15,
    order   : inventory_users.order || 'desc',
    orderBy : inventory_users.orderBy || 'id',
  };
  return (
    <Query query={InventoryUserQueries} variables={queryVars}>
      {({ loading, data, refetch }) => {
        if (!loading && inventory_users.loaded === false) {
          loadInventoryUserList({
            order           : queryVars.order,
            orderBy         : queryVars.orderBy,
            selected        : [],
            data            : data.getInventoryUsers,
            page            : inventory_users.page || 0,
            rowsPerPage     : queryVars.limit,
            count           : data.getInventoryUserCount,
            offset          : queryVars.offset,
            selectedColumns : data.getInventoryUserDefaultColumnNames,
            managers        : data.getManagers,
          });
        }
        return (
          <div>
            <InventoryUsers
              {...props}
              title={get(data, 'agentWordForCompany') ? `${startCase(data.agentWordForCompany)}s` : ''}
              company={get(data, 'getCurrentUser.company', {})}
              refetch={refetch}
              fb_columns={fb_columns}
              filterColumns={filteredInvUserColumns}
              currentUserIsAdmin={get(data, 'currentUserIsAdmin')}
              currentUserId={get(data, 'currentUserId')}
              loading={loading}
              tags={get(data, 'getInventoryUserTags', [])}
              agencies={get(data, 'getAgencies', [])}
              allFeatures={get(data, 'allFeatures', [])}
              bulkEditItems={bulkEditItems}
            />
          </div>
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  inventory_user  : get(state.inventory_users, 'inventoryUser', {}),
  inventory_users : state.inventory_users,
  bulkEditItems   : state.bulkEditItems,
  state,
});

InventoryUserContainer.propTypes = {
  inventory_users       : PropTypes.object.isRequired,
  loadInventoryUserList : PropTypes.func.isRequired,
  bulkEditItems         : PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  ...actions,
  ...marketplaceActions,
  ...bulkEditUserActions,
  ...userActions,
})(InventoryUserContainer);
