import React from 'react';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import numeral from 'numeral';
import { get, filter, find, startCase, upperFirst, toUpper, reject, lowerCase } from 'lodash';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import initials from 'initials';
import { regionForCountry } from '../../lib/findBobUtils';
import InviteButton from '../InviteButton/InviteButton';
import { ifpOptions, aumOptions } from '../SignUpWizard/ProductMix';
import { interestsChip } from '../InventoryUserDrawer/OverviewUserData';
import InventoryUserAvatar from '../InventoryUserAvatar/InventoryUserAvatar';

/*
{
    id             : 'birth_date',
    dataType       : 'number',
    disablePadding : false,
    label          : 'Age',
    filter         : true,
    filterType     : 'range',
    customStyle : {}, // optional
    renderMethod   : n => {},
    },
*/

const render_ifp = (n, id, intl) => get(find(ifpOptions(intl), { value: get(n, id) }), 'label', '-');

export const render_dollar_values = (n, att) => {
  return get(n, att, null) !== null ? numeral(get(n, att, 0)).format('$0,0.00') : '-';
};

const render_bool = (n, att) => {
  const val = get(n, att, null);
  if (val !== null) {
    return val === true ? 'Yes' : 'No';
  }
  return '-';
};

export const ifp_template = {
  id             : '',
  dataType       : 'number',
  disablePadding : false,
  label          : '',
  filter         : true,
  filterType     : 'range',
  renderMethod   : n => render_ifp(n, ''),
};

export const fb_columns = ({
  inventory_users,
  updateInventoryUser,
  updateSelectedInventoryUser,
  classes,
  data,
  tags,
  intl,
  company,
}) => {
  const filterTags = [ { name: 'None', __typename: 'Tags' }, ...tags ];
  const companyColumns = [
    {
      id             : 'name',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Name',
      filter         : false,
      customStyle    : {
        position   : 'sticky',
        left       : 50,
        zIndex     : 10,
        background : 'white',
      },
      customWidth    : 150,
      renderMethod   : inventoryUser => (
        <span className={classes.nameField}>
          <span className={classes.iconContainer}>
            <InventoryUserAvatar inventoryUser={inventoryUser} />

            {inventoryUser.is_customer && <VerifiedUserIcon color="primary" />}
          </span>
          <span style={{ verticalAlign: 'middle' }}>{inventoryUser.name}</span>
        </span>
      ),
    },
    {
      id             : 'email',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Email',
      filter         : false,
      renderMethod   : n => n.email || '-',
    },
    {
      id             : 'marketplace_status',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Marketplace Status',
      filter         : true,
      ransack_filter : 'marketplace_status',
      filterOptions  :
        company.business_type === 'wealth_rj'
          ? [ { name: 'Invite', value: '0' }, { name: 'Pending', value: '1' }, { name: 'Joined', value: '2' } ]
          : [
              { name: 'Invite', value: '0' },
              { name: 'Pending', value: '1' },
              { name: 'Joined', value: '2' },
              { name: 'Activated', value: '3' },
            ],
      renderMethod   : inventoryUser => (
        <span className={classes.invite}>
          <InviteButton
            type={inventoryUser.marketplace_status}
            inviteeId={inventoryUser.id}
            inviteeName={inventoryUser.name}
            inventory_users={inventory_users}
            updateInventoryUser={updateInventoryUser}
            updateAction={updateSelectedInventoryUser}
            company={inventoryUser.company}
          />
        </span>
      ),
    },
    {
      id             : 'transition_goals',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Transition Goals',
      filter         : true,
      ransack_filter : 'transition_goals_name',
      filterOptions  : [
        { name: 'None', value: 'None' },
        { name: 'Buying', value: 'Buying' },
        { name: 'Merging', value: 'Merging' },
        { name: 'Partnering', value: 'Partnering' },
        { name: 'Business Continuity', value: 'Business Continuity' },
        { name: 'Find a Successor', value: 'Find a Successor' },
        { name: 'Become a Successor', value: 'Become a Successor' },
        { name: 'Becoming a Junior Partner', value: 'Becoming a Junior Partner' },
        { name: 'Selling my Practice', value: 'Selling my practice' },
        {
          name  : 'Selling part of my practice',
          value : 'Selling part of my practice',
        },
        { name: 'Mentoring ', value: 'Mentoring' },
        { name: 'Being Mentored', value: 'Being Mentored' },
      ],
      renderMethod   : n => (n.transition_goals || []).map(t => interestsChip(t, classes)),
    },
    {
      id             : 'province',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Province/State',
      filter         : true,
      ransack_filter : 'province',
      filterOptions  : regionForCountry(
        company.country,
        location.hostname === 'app.findbob.io' || location.hostname === 'staging.findbob.io'
      ).map(c => ({ name: c, value: c })),
    },
    {
      id             : 'city',
      dataType       : 'string',
      disablePadding : false,
      label          : 'City',
      filter         : false,
      ransack_filter : 'city',
      renderMethod   : n => n.city || '-',
    },
    {
      id             : 'birth_date',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Age',
      filter         : true,
      ransack_filter : 'birth_date',
      filterType     : 'date',
      reverseOrderBy : true,
      renderMethod   : n => moment().diff(n.birth_date, 'years') || '-',
    },
    {
      id             : 'start_date',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Firm Length of Service',
      filterType     : 'date',
      filter         : true,
      ransack_filter : 'start_date',
      renderMethod   : n => `${moment().diff(n.start_date, 'years')} years`,
    },
    {
      id             : 'minimum_profile_completed',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Minimum Profile Completed',
      filter         : true,
      ransack_filter : 'minimum_profile_completed',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'minimum_profile_completed'),
    },
    {
      id             : 'rev_yield',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Revenue',
      filter         : true,
      ransack_filter : 'rev_yield',
      filterType     : 'range',
      renderMethod   : n => render_dollar_values(n, 'rev_yield'),
    },
    {
      id             : 'managed_by',
      dataType       : 'user',
      filterType     : 'manager',
      disablePadding : false,
      label          : 'Managed By',
      filter         : true,
      ransack_filter : 'managed_by_users_id',
      renderMethod   : n => get(n, 'manager.name', ''),
    },
    {
      id             : 'is_invisible',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Invisible?',
      filter         : true,
      ransack_filter : 'is_invisible',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_invisible'),
    },
    {
      id            : 'is_student',
      dataType      : 'string',
      disablePadding : false,
      label         : 'Student?',
      filter        : true,
      ransack_filter : 'is_student',
      filterOptions : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod  : n => render_bool(n, 'is_student'),
    },
    {
      id            : 'segmented_view',
      dataType      : 'string',
      disablePadding : false,
      label         : 'Segmented View',
      filter        : true,
      ransack_filter : 'segmented_view',
      renderMethod  : n => get(n, 'segmented_view', '-'),
    },
    {
      id             : 'approvers',
      dataType       : 'text',
      filterType     : 'text',
      disablePadding : false,
      label          : 'Approvers',
      filter         : true,
      ransack_filter : 'manager_approvers',
      renderMethod   : n => get(n, 'approvers', '').split(' ').map(w => upperFirst(w)).join(' '),
    },
    {
      id             : 'home_ifp',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Home IFP (Millions)',
      filter         : true,
      ransack_filter : 'home_ifp',
      filterType     : 'range',
      renderMethod   : n => render_dollar_values(n, 'home_ifp'),
    },
    {
      id             : 'life_ifp',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Life IFP (Millions)',
      filter         : true,
      ransack_filter : 'life_ifp',
      filterType     : 'range',
      renderMethod   : n => render_dollar_values(n, 'life_ifp'),
    },
    {
      id             : 'auto_ifp',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Auto IFP',
      filter         : true,
      ransack_filter : 'auto_ifp',
      filterType     : 'range',
      renderMethod   : n => render_dollar_values(n, 'auto_ifp'),
    },
    {
      id             : 'commercial_ifp',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Commercial IFP',
      filter         : true,
      ransack_filter : 'commercial_ifp',
      filterType     : 'range',
      renderMethod   : n => render_dollar_values(n, 'commercial_ifp'),
    },
    {
      id             : 'iu_tags',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Tags',
      filter         : true,
      ransack_filter : 'iu_tags_name',
      renderMethod   : n =>
        (n.iu_tags || [])
          .map(t => (t.name.includes('csv') ? <Chip label={toUpper(t.name)} /> : <Chip label={upperFirst(t.name)} />)),
      filterOptions  : filterTags.map(g => ({ name: g.name, value: g.name })),
    },
    {
      id             : 'key_asset',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Key Asset?',
      filter         : true,
      ransack_filter : 'key_asset',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'key_asset'),
    },
    {
      id             : 'is_exception',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Is Exception?',
      filter         : true,
      ransack_filter : 'is_exception',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_exception'),
    },
    {
      id             : 'in_good_standing',
      dataType       : 'string',
      disablePadding : false,
      label          : 'In Good Standing?',
      filter         : true,
      ransack_filter : 'in_good_standing',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'in_good_standing'),
    },
    {
      id             : 'risk_of_loss_num',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Risk Of Loss',
      filter         : true,
      ransack_filter : 'risk_of_loss_num',
      filterOptions  : [
        { name: 'None', value: '0' },
        { name: 'Low', value: '1' },
        { name: 'Medium', value: '2' },
        { name: 'High', value: '3' },
      ],
      renderMethod   : n => (n.risk_of_loss_num ? startCase(n.risk_of_loss_num.replace('risk', '')) : '-'),
    },
    {
      id             : 'impact_of_loss_num',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Impact Of Loss',
      filter         : true,
      ransack_filter : 'impact_of_loss_num',
      filterOptions  : [
        { name: 'None', value: '0' },
        { name: 'Low', value: '1' },
        { name: 'Medium', value: '2' },
        { name: 'High', value: '3' },
      ],
      renderMethod   : n => (n.impact_of_loss_num ? startCase(n.impact_of_loss_num.replace('impact', '')) : '-'),
    },
    {
      id             : 'bench_count',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Bench Nominations',
      renderMethod   : n => n.bench_count || '-',
    },
    {
      ...ifp_template,
      id             : 'avg_client_age',
      label          : 'Avg. Client Age',
      ransack_filter : 'avg_client_age',
      renderMethod   : n => n.avg_client_age || '-',
    },
    {
      ...ifp_template,
      id             : 'recurring_revenue',
      label          : 'Recurring Revenue',
      ransack_filter : 'recurring_revenue',
      renderMethod   : n => render_dollar_values(n, 'recurring_revenue'),
    },
    {
      ...ifp_template,
      id             : 'health_ifp',
      label          : 'Health ifp',
      ransack_filter : 'health_ifp',
      renderMethod   : n => render_dollar_values(n, 'health_ifp'),
    },
    {
      ...ifp_template,
      id             : 'group_ifp',
      label          : 'Group ifp',
      ransack_filter : 'group_ifp',
      renderMethod   : n => render_dollar_values(n, 'group_ifp'),
    },
    {
      ...ifp_template,
      id             : 'mutual_funds_aum',
      label          : 'Mutual Funds (aum)',
      ransack_filter : 'mutual_funds_aum',
      renderMethod   : n => render_dollar_values(n, 'mutual_funds_aum'),
    },
    {
      ...ifp_template,
      id             : 'securities_aum',
      label          : 'Securities (aum)',
      ransack_filter : 'securities_aum',
      renderMethod   : n => render_dollar_values(n, 'securities_aum'),
    },
    {
      ...ifp_template,
      id             : 'annuities_aua',
      label          : 'Annuities (aua)',
      ransack_filter : 'annuities_aua',
      renderMethod   : n => render_dollar_values(n, 'annuities_aua'),
    },
    {
      ...ifp_template,
      id             : 'segregated_funds_aua',
      label          : 'Segregated Funds (aua)',
      ransack_filter : 'segregated_funds_aua',
      renderMethod   : n => render_dollar_values(n, 'segregated_funds_aua'),
    },
    {
      ...ifp_template,
      id             : 'mcpi_ifp',
      label          : 'MCPI (ifp)',
      ransack_filter : 'mcpi_ifp',
      renderMethod   : n => render_dollar_values(n, 'mcpi_ifp'),
    },
    {
      ...ifp_template,
      id             : 'private_products_ifp',
      label          : 'Private Products (ifp)',
      ransack_filter : 'private_products_ifp',
      renderMethod   : n => render_dollar_values(n, 'private_products_ifp'),
    },
    {
      ...ifp_template,
      id             : 'crop_hail_ifp',
      label          : 'Crop Hail (ifp)',
      ransack_filter : 'crop_hail_ifp',
      renderMethod   : n => render_dollar_values(n, 'crop_hail_ifp'),
    },
    {
      ...ifp_template,
      id             : 'crop_hail_with_wind_ifp',
      label          : 'Crop Hail with Wind (ifp)',
      ransack_filter : 'crop_hail_with_wind_ifp',
      renderMethod   : n => render_dollar_values(n, 'crop_hail_with_wind_ifp'),
    },
    {
      ...ifp_template,
      id             : 'price_products_ifp',
      label          : 'Price Products (ifp)',
      ransack_filter : 'price_products_ifp',
      renderMethod   : n => render_dollar_values(n, 'price_products_ifp'),
    },
    {
      ...ifp_template,
      id             : 'replant_supplement_ifp',
      label          : 'Replant Supplement (ifp)',
      ransack_filter : 'replant_supplement_ifp',
      renderMethod   : n => render_dollar_values(n, 'replant_supplement_ifp'),
    },
    {
      ...ifp_template,
      id             : 'farm_insurance_ifp',
      label          : 'Farm Insurance (ifp)',
      ransack_filter : 'farm_insurance_ifp',
      renderMethod   : n => render_dollar_values(n, 'farm_insurance_ifp'),
    },
    {
      ...ifp_template,
      id             : 'clients',
      label          : 'Clients',
      ransack_filter : 'clients',
      renderMethod   : n => n.clients || '-',
    },
    {
      id             : 'region',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Region',
      filter         : false,
      ransack_filter : 'region',
      renderMethod   : n => n.region || '-',
    },
    {
      id             : 'affiliation',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Affiliation',
      filter         : false,
      ransack_filter : 'affiliation',
      renderMethod   : n => n.affiliation || '-',
    },
    {
      id             : 'division',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Division',
      filter         : false,
      ransack_filter : 'division',
      renderMethod   : n => n.division || '-',
    },
    {
      id             : 'branch',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch',
      filter         : false,
      ransack_filter : 'branch',
      renderMethod   : n => n.branch || '-',
    },
    {
      id             : 'is_branch_exception',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch Exclusion',
      filter         : true,
      ransack_filter : 'is_branch_exception',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_branch_exception'),
    },
    {
      id             : 'website',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Website',
      filter         : false,
      ransack_filter : 'website',
      renderMethod   : n => n.website || '-',
    },
    {
      id             : 'catestrophic_plan_on_file',
      dataType       : 'string',
      disablePadding : false,
      label          : `${get(company, 'catastrophic_planning_word', 'Catastrophic')} Plan On File`,
      filter         : false,
      ransack_filter : 'catestrophic_plan_on_file',
      renderMethod   : n => n.catestrophic_plan_on_file || '-',
    },
    {
      id             : 'alex_brown_flag',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Alex Brown Flag',
      filter         : true,
      ransack_filter : 'alex_brown_flag',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'alex_brown_flag'),
    },
    {
      id             : 'user_motivation',
      dataType       : 'string',
      disablePadding : false,
      label          : 'User Motivation',
      filter         : true,
      ransack_filter : 'user_motivation',
      filterOptions  : [
        { name: 'Growth', value: 'growth' },
        { name: 'Succession', value: 'succession' },
        { name: 'No Motivation', value: 'no_motivation' },
      ],
      renderMethod   : n => upperFirst(n.user_motivation) || '-',
    },
    {
      id             : 'recognition_level',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Recognition Level',
      filter         : true,
      filterOptions  : [
        { name: 'Achievers Club', value: 'Achievers Club' },
        { name: "Chairman's Council", value: "Chairman's Council" },
        { name: "Chairman's Council-1", value: "Chairman's Council-1" },
        { name: "Chairman's Council-2", value: "Chairman's Council-2" },
        { name: "Chairman's Council-3", value: "Chairman's Council-3" },
        { name: "Chairman's Council-4", value: "Chairman's Council-4" },
        { name: 'Executive Council', value: 'Executive Council' },
        { name: "FID Director's Council", value: "FID Director's Council" },
        { name: "ICD Director's Council", value: "ICD Director's Council" },
        { name: 'Leaders Club-1', value: 'Leaders Club-1' },
        { name: 'Leaders Club-2', value: 'Leaders Club-2' },
        { name: 'Leaders Council', value: 'Leaders Council' },
        { name: "President's Club-1", value: "President's Club-1" },
        { name: "President's Club-2", value: "President's Club-2" },
      ],
      ransack_filter : 'recognition_level',
    },
    {
      id             : 'industry_length_of_service',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Industry Length of Service',
      filterType     : 'range',
      filter         : true,
      ransack_filter : 'industry_length_of_service',
      renderMethod   : n => (n.industry_length_of_service ? `${n.industry_length_of_service} years` : '-'),
    },
    {
      id             : 'team_size',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Team Size',
      filter         : true,
      ransack_filter : 'team_size',
      filterType     : 'range',
      renderMethod   : n => get(n, 'team_size'),
    },
    {
      id             : 'team_name',
      dataType       : 'text',
      disablePadding : false,
      label          : 'Team Name',
      filter         : true,
      filterType     : 'text',
      ransack_filter : 'team_name',
      renderMethod   : n => get(n, 'team_name', '-'),
    },
    {
      id             : 'team_enhanced_compensation',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Team Enhanced Compensation',
      filter         : true,
      ransack_filter : 'team_enhanced_compensation',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'team_enhanced_compensation'),
    },
    {
      id             : 'amp_trainee_status',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Amp Trainee Status',
      filter         : true,
      ransack_filter : 'amp_trainee_status',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'amp_trainee_status'),
    },
    {
      id             : 'heightened_supervision',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Heightened Supervision',
      filter         : true,
      ransack_filter : 'heightened_supervision',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'heightened_supervision'),
    },
    {
      id             : 'cssid',
      dataType       : 'number',
      disablePadding : false,
      label          : 'CSSID',
      ransack_filter : 'cssid',
      renderMethod   : n => get(n, 'cssid'),
    },
    {
      id             : 'office_city',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch City',
      filter         : false,
      ransack_filter : 'office_city',
      renderMethod   : n => n.office_city || '-',
    },
    {
      id             : 'office_state',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch State',
      filter         : true,
      filterOptions  : regionForCountry(
        company.country,
        location.hostname === 'app.findbob.io' || location.hostname === 'staging.findbob.io'
      ).map(c => ({ name: c, value: c })),
      ransack_filter : 'office_state',
      renderMethod   : n => n.office_state || '-',
    },
    {
      id             : 'office_postal_code',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch Zip',
      filter         : false,
      ransack_filter : 'office_postal_code',
      renderMethod   : n => n.office_postal_code || '-',
    },
    {
      id             : 'insurance_business',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Insurance Business',
      filter         : true,
      ransack_filter : 'insurance_business',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'insurance_business'),
    },
    {
      id             : 'discretion',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Discretion',
      filter         : true,
      ransack_filter : 'discretion',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'discretion'),
    },
    {
      ...ifp_template,
      id             : 'trailer_revenue',
      label          : company.business_type === 'wealth_rj' ? 'Trail Revenue' : 'Trailer Revenue',
      ransack_filter : 'trailer_revenue',
      renderMethod   : n => render_dollar_values(n, 'trailer_revenue'),
    },
    {
      ...ifp_template,
      id             : 'direct_revenue',
      label          : 'Direct Revenue',
      ransack_filter : 'direct_revenue',
      renderMethod   : n => render_dollar_values(n, 'direct_revenue'),
    },
    {
      ...ifp_template,
      id             : 'transactional_revenue',
      label          : 'Transactional Revenue',
      ransack_filter : 'transactional_revenue',
      renderMethod   : n => render_dollar_values(n, 'transactional_revenue'),
    },
    {
      ...ifp_template,
      id             : 'fee_based_revenue',
      label          : 'Fee Based Revenue',
      ransack_filter : 'fee_based_revenue',
      renderMethod   : n => render_dollar_values(n, 'fee_based_revenue'),
    },
    {
      ...ifp_template,
      id             : 'total_assets',
      label          : 'Total Assets',
      ransack_filter : 'total_assets',
      renderMethod   : n => render_dollar_values(n, 'total_assets'),
    },
    {
      ...ifp_template,
      id             : 'pct_client_0to18',
      label          : 'Clients Aged 0 to 18',
      ransack_filter : 'pct_client_0to18',
      renderMethod   : n => `${get(n, 'pct_client_0to18', 0)}%`,
    },
    {
      ...ifp_template,
      id             : 'pct_client_18to34',
      label          : 'Clients Aged 18 to 34',
      ransack_filter : 'pct_client_18to34',
      renderMethod   : n => `${get(n, 'pct_client_18to34', 0)}%`,
    },
    {
      ...ifp_template,
      id             : 'pct_client_35to50',
      label          : 'Clients Aged 35 to 50',
      ransack_filter : 'pct_client_35to50',
      renderMethod   : n => `${get(n, 'pct_client_35to50', 0)}%`,
    },
    {
      ...ifp_template,
      id             : 'pct_client_51to70',
      label          : 'Clients Aged 51 to 70',
      ransack_filter : 'pct_client_51to70',
      renderMethod   : n => `${get(n, 'pct_client_51to70', 0)}%`,
    },
    {
      ...ifp_template,
      id             : 'pct_client_71plus',
      label          : 'Clients Aged 71 Plus',
      ransack_filter : 'pct_client_71plus',
      renderMethod   : n => `${get(n, 'pct_client_71plus', 0)}%`,
    },
    {
      id             : 'series_7',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Series 7',
      filter         : true,
      ransack_filter : 'series_7',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'series_7'),
    },
    {
      id             : 'series_63',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Series 63',
      filter         : true,
      ransack_filter : 'series_63',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'series_63'),
    },
    {
      id             : 'series_65',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Series 65',
      filter         : true,
      ransack_filter : 'series_65',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'series_65'),
    },
    {
      id             : 'series_66',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Series 66',
      filter         : true,
      ransack_filter : 'series_66',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'series_66'),
    },
    {
      id             : 'retirement_choice_calculator_code',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Retirement Choice Calculator Code',
      filter         : false,
      ransack_filter : 'retirement_choice_calculator_code',
    },
    {
      id             : 'state_registrations',
      dataType       : 'string',
      disablePadding : false,
      label          : 'State Registrations',
      filter         : true,
      ransack_filter : 'state_registrations',
      filterOptions  : regionForCountry(
        company.country,
        location.hostname === 'app.findbob.io' || location.hostname === 'staging.findbob.io'
      ).map(c => ({ name: `${c}`, value: lowerCase(c) })),
      renderMethod   : n => get(n, 'state_registrations', []).map(s => `${s} `),
    },
    {
      id             : 'can_match',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Can Match',
      filter         : true,
      ransack_filter : 'can_match',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'can_match'),
    },
    {
      id             : 'talent_acquisition_user',
      dataType       : 'string',
      disablePadding : false,
      label          : 'External Talent',
      filter         : true,
      ransack_filter : 'talent_acquisition_user',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'talent_acquisition_user'),
    },    
    {
      id             : 'can_create_meeting_room',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Can Create Meeting Room',
      filter         : true,
      ransack_filter : 'can_create_meeting_room',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'can_create_meeting_room'),
    },
    {
      ...ifp_template,
      id             : 'market_value',
      label          : 'Market Value',
      ransack_filter : 'market_value',
      renderMethod   : n => render_dollar_values(n, 'market_value'),
    },
    {
      id             : 'market_value_percentile',
      disablePadding : false,
      label          : 'Market Value Percentile',
      filterType     : 'range',
      filter         : true,
      ransack_filter : 'market_value_percentile',
      renderMethod   : n => `${get(n, 'market_value_percentile', '')} %`,
    },
    {
      id             : 'updated_at',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Updated At',
      filterType     : 'date',
      filter         : false,
      ransack_filter : 'updated_at',
      renderMethod   : n =>
        moment(n.updated_at).isValid() ? moment(n.updated_at).format('MMM Do YYYY @HH:mm a') : '-',
    },
    {
      id             : 'created_at',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Created At',
      filterType     : 'date',
      filter         : false,
      ransack_filter : 'created_at',
      renderMethod   : n =>
        moment(n.created_at).isValid() ? moment(n.created_at).format('MMM Do YYYY @HH:mm a') : '-',
    },
    {
      ...ifp_template,
      id             : 'total_assets_2023',
      label          : 'Total Assets 2023',
      ransack_filter : 'total_assets_2023',
      renderMethod   : n => render_dollar_values(n, 'total_assets_2023'),
    },
    {
      ...ifp_template,
      id             : 'total_assets_2022',
      label          : 'Total Assets 2022',
      ransack_filter : 'total_assets_2022',
      renderMethod   : n => render_dollar_values(n, 'total_assets_2022'),
    },
    {
      ...ifp_template,
      id             : 'total_assets_2021',
      label          : 'Total Assets 2021',
      ransack_filter : 'total_assets_2021',
      renderMethod   : n => render_dollar_values(n, 'total_assets_2021'),
    },

    {
      ...ifp_template,
      id             : 'total_assets_2020',
      label          : 'Total Assets 2020',
      ransack_filter : 'total_assets_2020',
      renderMethod   : n => render_dollar_values(n, 'total_assets_2020'),
    },
    {
      ...ifp_template,
      id             : 'total_assets_2019',
      label          : 'Total Assets 2019',
      ransack_filter : 'total_assets_2019',
      renderMethod   : n => render_dollar_values(n, 'total_assets_2019'),
    },
    {
      ...ifp_template,
      id             : 'total_assets_2018',
      label          : 'Total Assets 2018',
      ransack_filter : 'total_assets_2018',
      renderMethod   : n => render_dollar_values(n, 'total_assets_2018'),
    },
    {
      ...ifp_template,
      id             : 'total_assets_2017',
      label          : 'Total Assets 2017',
      ransack_filter : 'total_assets_2017',
      renderMethod   : n => render_dollar_values(n, 'total_assets_2017'),
    },
    {
      ...ifp_template,
      id             : 'total_assets_2016',
      label          : 'Total Assets 2016',
      ransack_filter : 'total_assets_2016',
      renderMethod   : n => render_dollar_values(n, 'total_assets_2016'),
    },
    {
      ...ifp_template,
      id             : 'gross_production_2023',
      label          : 'Gross Production 2023',
      ransack_filter : 'gross_production_2023',
      renderMethod   : n => render_dollar_values(n, 'gross_production_2023'),
    },
    {
      ...ifp_template,
      id             : 'gross_production_2022',
      label          : 'Gross Production 2022',
      ransack_filter : 'gross_production_2022',
      renderMethod   : n => render_dollar_values(n, 'gross_production_2022'),
    },
    {
      ...ifp_template,
      id             : 'gross_production_2021',
      label          : 'Gross Production 2021',
      ransack_filter : 'gross_production_2021',
      renderMethod   : n => render_dollar_values(n, 'gross_production_2021'),
    },
    {
      ...ifp_template,
      id             : 'gross_production_2020',
      label          : 'Gross Production 2020',
      ransack_filter : 'gross_production_2020',
      renderMethod   : n => render_dollar_values(n, 'gross_production_2020'),
    },
    {
      ...ifp_template,
      id             : 'gross_production_2019',
      label          : 'Gross Production 2019',
      ransack_filter : 'gross_production_2019',
      renderMethod   : n => render_dollar_values(n, 'gross_production_2019'),
    },
    {
      ...ifp_template,
      id             : 'gross_production_2018',
      label          : 'Gross Production 2018',
      ransack_filter : 'gross_production_2018',
      renderMethod   : n => render_dollar_values(n, 'gross_production_2018'),
    },
    {
      ...ifp_template,
      id             : 'gross_production_2017',
      label          : 'Gross Production 2017',
      ransack_filter : 'gross_production_2017',
      renderMethod   : n => render_dollar_values(n, 'gross_production_2017'),
    },
    {
      ...ifp_template,
      id             : 'gross_production_2016',
      label          : 'Gross Production 2016',
      ransack_filter : 'gross_production_2016',
      renderMethod   : n => render_dollar_values(n, 'gross_production_2016'),
    },
    {
      id             : 'fifty_percent_clients_aged_65_or_older',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Fifty Percent Clients Aged 65 or Older',
      filter         : true,
      ransack_filter : 'fifty_percent_clients_aged_65_or_older',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'fifty_percent_clients_aged_65_or_older'),
    },
    {
      id             : 'bottom_two_tiers_recurring_revenue',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Bottom Two Tiers Reccuring Revenue',
      filter         : true,
      ransack_filter : 'bottom_two_tiers_recurring_revenue',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'bottom_two_tiers_recurring_revenue'),
    },
    {
      id             : 'nna_for_the_year',
      dataType       : 'string',
      disablePadding : false,
      label          : 'NNA For The Year',
      filter         : true,
      ransack_filter : 'nna_for_the_year',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'nna_for_the_year'),
    },
    {
      id             : 'greater_fifty_percent_revenue_top_twenty',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Greater Fifty Percent Revenue Top Twenty',
      filter         : true,
      ransack_filter : 'greater_fifty_percent_revenue_top_twenty',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'greater_fifty_percent_revenue_top_twenty'),
    },
    {
      ...ifp_template,
      id             : 'br_total_assets_2023',
      label          : 'Branch Total Assets 2023',
      ransack_filter : 'br_total_assets_2023',
      renderMethod   : n => render_dollar_values(n, 'br_total_assets_2023'),
    },
    {
      ...ifp_template,
      id             : 'br_total_assets_2022',
      label          : 'Branch Total Assets 2022',
      ransack_filter : 'br_total_assets_2022',
      renderMethod   : n => render_dollar_values(n, 'br_total_assets_2022'),
    },
    {
      ...ifp_template,
      id             : 'br_total_assets_2021',
      label          : 'Branch Total Assets 2021',
      ransack_filter : 'br_total_assets_2021',
      renderMethod   : n => render_dollar_values(n, 'br_total_assets_2021'),
    },
    {
      ...ifp_template,
      id             : 'br_total_assets_2020',
      label          : 'Branch Total Assets 2020',
      ransack_filter : 'br_total_assets_2020',
      renderMethod   : n => render_dollar_values(n, 'br_total_assets_2020'),
    },
    {
      ...ifp_template,
      id             : 'br_total_assets_2019',
      label          : 'Branch Total Assets 2019',
      ransack_filter : 'br_total_assets_2019',
      renderMethod   : n => render_dollar_values(n, 'br_total_assets_2019'),
    },
    {
      ...ifp_template,
      id             : 'br_total_assets_2018',
      label          : 'Branch Total Assets 2018',
      ransack_filter : 'br_total_assets_2018',
      renderMethod   : n => render_dollar_values(n, 'br_total_assets_2018'),
    },
    {
      ...ifp_template,
      id             : 'br_total_assets_2017',
      label          : 'Branch Total Assets 2017',
      ransack_filter : 'br_total_assets_2017',
      renderMethod   : n => render_dollar_values(n, 'br_total_assets_2017'),
    },
    {
      ...ifp_template,
      id             : 'br_total_assets_2016',
      label          : 'Branch Total Assets 2016',
      ransack_filter : 'br_total_assets_2016',
      renderMethod   : n => render_dollar_values(n, 'br_total_assets_2016'),
    },
    {
      ...ifp_template,
      id             : 'br_gross_production_2023',
      label          : 'Branch Gross Production 2023',
      ransack_filter : 'br_gross_production_2023',
      renderMethod   : n => render_dollar_values(n, 'br_gross_production_2023'),
    },
    {
      ...ifp_template,
      id             : 'br_gross_production_2022',
      label          : 'Branch Gross Production 2022',
      ransack_filter : 'br_gross_production_2022',
      renderMethod   : n => render_dollar_values(n, 'br_gross_production_2022'),
    },
    {
      ...ifp_template,
      id             : 'br_gross_production_2021',
      label          : 'Branch Gross Production 2021',
      ransack_filter : 'br_gross_production_2021',
      renderMethod   : n => render_dollar_values(n, 'br_gross_production_2021'),
    },

    {
      ...ifp_template,
      id             : 'br_gross_production_2020',
      label          : 'Branch Gross Production 2020',
      ransack_filter : 'br_gross_production_2020',
      renderMethod   : n => render_dollar_values(n, 'br_gross_production_2020'),
    },
    {
      ...ifp_template,
      id             : 'br_gross_production_2019',
      label          : 'Branch Gross Production 2019',
      ransack_filter : 'br_gross_production_2019',
      renderMethod   : n => render_dollar_values(n, 'br_gross_production_2019'),
    },
    {
      ...ifp_template,
      id             : 'br_gross_production_2018',
      label          : 'Branch Gross Production 2018',
      ransack_filter : 'br_gross_production_2018',
      renderMethod   : n => render_dollar_values(n, 'br_gross_production_2018'),
    },
    {
      ...ifp_template,
      id             : 'br_gross_production_2017',
      label          : 'Branch Gross Production 2017',
      ransack_filter : 'br_gross_production_2017',
      renderMethod   : n => render_dollar_values(n, 'br_gross_production_2017'),
    },
    {
      ...ifp_template,
      id             : 'br_gross_production_2016',
      label          : 'Branch Gross Production 2016',
      ransack_filter : 'br_gross_production_2016',
      renderMethod   : n => render_dollar_values(n, 'br_gross_production_2016'),
    },
    {
      id             : 'br_fifty_percent_clients_aged_65_or_older',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch Fifty Percent Clients Aged 65 or Older',
      filter         : true,
      ransack_filter : 'br_fifty_percent_clients_aged_65_or_older',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'br_fifty_percent_clients_aged_65_or_older'),
    },
    {
      id             : 'br_bottom_two_tiers_recurring_revenue',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch Bottom Two Tiers Reccuring Revenue',
      filter         : true,
      ransack_filter : 'br_bottom_two_tiers_recurring_revenue',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'br_bottom_two_tiers_recurring_revenue'),
    },
    {
      id             : 'br_nna_for_the_year',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch NNA For The Year',
      filter         : true,
      ransack_filter : 'br_nna_for_the_year',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'br_nna_for_the_year'),
    },
    {
      id             : 'br_greater_fifty_percent_revenue_top_twenty',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch Greater Fifty Percent Revenue Top Twenty',
      filter         : true,
      ransack_filter : 'br_greater_fifty_percent_revenue_top_twenty',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'br_greater_fifty_percent_revenue_top_twenty'),
    },
    {
      id             : 'is_branch_manager',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Is Branch Manager',
      filter         : true,
      ransack_filter : 'is_branch_manager',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_branch_manager'),
    },
    {
      id             : 'no_named_successor',
      dataType       : 'string',
      disablePadding : false,
      label          : 'No Named Successor',
      filter         : true,
      ransack_filter : 'no_named_successor',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'no_named_successor'),
    },
    {
      ...ifp_template,
      id             : 'br_practice_size',
      label          : 'Branch Practice Size',
      ransack_filter : 'br_practice_size',
      renderMethod   : n => render_dollar_values(n, 'br_practice_size'),
    },
    {
      ...ifp_template,
      id             : 'br_production',
      label          : 'Branch Production',
      ransack_filter : 'br_production',
      renderMethod   : n => render_dollar_values(n, 'br_production'),
    },
    {
      ...ifp_template,
      id             : 'br_transactional_revenue',
      label          : 'Branch Transactional Revenue',
      ransack_filter : 'br_transactional_revenue',
      renderMethod   : n => render_dollar_values(n, 'br_transactional_revenue'),
    },
    {
      ...ifp_template,
      id             : 'br_fee_based_revenue',
      label          : 'Branch Fee Based Revenue',
      ransack_filter : 'br_fee_based_revenue',
      renderMethod   : n => render_dollar_values(n, 'br_fee_based_revenue'),
    },
    {
      ...ifp_template,
      id             : 'br_trails_revenue',
      label          : 'Branch Trails Revenue',
      ransack_filter : 'Branch Trails Revenue',
      renderMethod   : n => render_dollar_values(n, 'br_trails_revenue'),
    },
    {
      ...ifp_template,
      id             : 'br_directs_revenue',
      label          : 'Branch Directs Revenue',
      ransack_filter : 'br_directs_revenue',
      renderMethod   : n => render_dollar_values(n, 'br_directs_revenue'),
    },
    {
      ...ifp_template,
      id             : 'average_age',
      label          : 'Average Age',
      ransack_filter : 'average_age',
      renderMethod   : n => (n.average_age ? numeral(n.average_age).format('0') : '-'),
    },
    {
      id             : 'is_rjas',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Is RJAS',
      filter         : true,
      ransack_filter : 'is_rjas',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_rjas'),
    },
    {
      id             : 'is_complex_manager',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Is Complex Manager',
      filter         : true,
      ransack_filter : 'is_complex_manager',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_complex_manager'),
    },
    {
      id             : 'is_fid',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Is FID',
      filter         : true,
      ransack_filter : 'is_fid',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_fid'),
    },
    {
      id             : 'is_bank_program_manager',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Is Bank Program Manager',
      filter         : true,
      ransack_filter : 'is_bank_program_manager',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_bank_program_manager'),
    },
    {
      id             : 'agreement_type_for_for_catastrophic_plan',
      dataType       : 'number',
      disablePadding : false,
      label          : `Agreement Type For ${get(company, 'catastrophic_planning_word', 'Catastrophic')} Plan`,
      ransack_filter : 'agreement_type_for_for_catastrophic_plan',
      renderMethod   : n => get(n, 'agreement_type_for_for_catastrophic_plan'),
    },
    {
      id             : 'catastrophic_plan_contract_date',
      dataType       : 'date',
      disablePadding : false,
      filter         : true,
      filterType     : 'range',
      label          : `${get(company, 'catastrophic_planning_word', 'Catastrophic')} Plan Contract Date`,
      ransack_filter : 'catastrophic_plan_contract_date',
      renderMethod   : n => moment(n.catastrophic_plan_contract_date).isValid() ? moment(n.catastrophic_plan_contract_date).format('MMM Do YYYY @HH:mm a') : '-',
    },
    {
      ...ifp_template,
      id             : 'br_average_age',
      label          : 'Branch Average Age',
      ransack_filter : 'br_average_age',
      renderMethod   : n => (n.br_average_age ? numeral(n.br_average_age).format('0') : '-'),
    },
    {
      ...ifp_template,
      id             : 'br_number_of_relationships',
      label          : 'Branch Number of Relationships',
      ransack_filter : 'br_number_of_relationships',
      renderMethod   : n => n.br_number_of_relationships || '-',
    },
    {
      id             : 'divisional_director_name',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Divisional Director Name',
      ransack_filter : 'divisional_director_name',
      renderMethod   : n => get(n, 'divisional_director_name'),
    },
    {
      id             : 'divisional_director_email',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Divisional Director Email',
      ransack_filter : 'divisional_director_email',
      renderMethod   : n => get(n, 'divisional_director_email'),
    },
    {
      id             : 'divisional_director_cssid',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Divisional Director CSSID',
      ransack_filter : 'divisional_director_cssid',
      renderMethod   : n => get(n, 'divisional_director_cssid'),
    },
    {
      id             : 'asst_divisional_director1_name',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Asst Divisional Director1 Name',
      ransack_filter : 'asst_divisional_director1_name',
      renderMethod   : n => get(n, 'asst_divisional_director1_name'),
    },
    {
      id             : 'asst_divisional_director1_email',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Asst Divisional Director1 Email',
      ransack_filter : 'asst_divisional_director1_email',
      renderMethod   : n => get(n, 'asst_divisional_director1_email'),
    },
    {
      id             : 'asst_divisional_director1_cssid',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Asst Divisional Director1 CSSID',
      ransack_filter : 'asst_divisional_director1_cssid',
      renderMethod   : n => get(n, 'asst_divisional_director1_cssid'),
    },
    {
      id             : 'asst_divisional_director2_name',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Asst Divisional Director2 Name',
      ransack_filter : 'asst_divisional_director2_name',
      renderMethod   : n => get(n, 'asst_divisional_director2_name'),
    },
    {
      id             : 'asst_divisional_director2_email',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Asst Divisional Director2 Email',
      ransack_filter : 'asst_divisional_director2_email',
      renderMethod   : n => get(n, 'asst_divisional_director2_email'),
    },
    {
      id             : 'asst_divisional_director2_cssid',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Asst Divisional Director2 CSSID',
      ransack_filter : 'asst_divisional_director2_cssid',
      renderMethod   : n => get(n, 'asst_divisional_director2_cssid'),
    },
    {
      id             : 'emplid',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Employee ID',
      ransack_filter : 'emplid',
      renderMethod   : n => get(n, 'emplid'),
    },
    {
      id             : 'successor1_emplid',
      dataType       : 'string',
      disablePadding : false,
      label          : 'successor1_emplid',
      ransack_filter : 'successor1_emplid',
      renderMethod   : n => get(n, 'successor1_emplid'),
    },
    {
      id             : 'successor2_emplid',
      dataType       : 'string',
      disablePadding : false,
      label          : 'successor2_emplid',
      ransack_filter : 'successor2_emplid',
      renderMethod   : n => get(n, 'successor2_emplid'),
    },
    {
      id             : 'successor3_emplid',
      dataType       : 'string',
      disablePadding : false,
      label          : 'successor3_emplid',
      ransack_filter : 'successor3_emplid',
      renderMethod   : n => get(n, 'successor3_emplid'),
    },
    {
      id             : 'successor4_emplid',
      dataType       : 'string',
      disablePadding : false,
      label          : 'successor4_emplid',
      ransack_filter : 'successor4_emplid',
      renderMethod   : n => get(n, 'successor4_emplid'),
    },
    {
      id             : 'successor5_emplid',
      dataType       : 'string',
      disablePadding : false,
      label          : 'successor5_emplid',
      ransack_filter : 'successor5_emplid',
      renderMethod   : n => get(n, 'successor5_emplid'),
    },
    {
      id             : 'planning_preference',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Planning Preference',
      filter         : true,
      ransack_filter : 'planning_preference',
      filterOptions  : [
        { name: 'None', value: '' },
        { name: 'Find Partner', value: 'find_partner' },
        { name: 'Become Partner', value: 'become_partner' },
        { name: 'No Plan Preference', value: 'no_plan_preference' },
      ],
      renderMethod   : n => get(n, 'planning_preference'),
    },
    {
      id             : 'manager_supervisor_info_emplid',
      dataType       : 'string',
      disablePadding : false,
      label          : 'manager_supervisor_info_emplid',
      ransack_filter : 'manager_supervisor_info_emplid',
      renderMethod   : n => get(n, 'manager_supervisor_info_emplid'),
    },
    {
      id             : 'agreement_date',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Agreement Date',
      ransack_filter : 'agreement_date',
      renderMethod   : n => get(n, 'agreement_date'),
    },
    {
      id             : 'approve_user_on_create',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Approve User On Create',
      filter         : true,
      ransack_filter : 'approve_user_on_create',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'approve_user_on_create'),
    },
    {
      id             : 'three_yr_avg_t12',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Three Year Average T12',
      filter         : true,
      ransack_filter : 'three_yr_avg_t12',
      filterType     : 'range',
      renderMethod   : n => render_dollar_values(n, 'three_yr_avg_t12'),
    },
    {
      id             : 'hide_on_create',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Hide On Create',
      filter         : true,
      ransack_filter : 'hide_on_create',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'hide_on_create'),
    },
{
      id             : 'customer_on_create',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Customer On Create',
      filter         : true,
      ransack_filter : 'customer_on_create',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'customer_on_create'),
    },
  ];

  let hiddenCols = get(company, 'hidden_inventory_user_columns', [ '' ]);
  if (get(company, 'add_age_column_to_tables')) {
    hiddenCols = hiddenCols.filter(e => e !== 'birth_date');
  }
  return reject(companyColumns, c => hiddenCols.includes(c.id));
};

export const filteredInvUserColumns = props => {
  const columns = fb_columns(props);
  const { inventory_users } = props;
  const selected = inventory_users.selectedColumns || [ '' ];
  return filter(columns, c => selected.includes(c.id));
};

export default fb_columns;
